<template>
  <div class="container">
    <div class="part">
      <div class="title">开通教练</div>

      <el-input v-model="keyWord" placeholder="教练电话或名字"></el-input>
      <el-button type="primary" @click="getcoachList(1)">搜索</el-button>

      <div v-if="show">
        <auto-table
          :DataList="DataList"
          :option="Option"
          :total="Total"
          @changePage="getcoachList"
        >
          <template #avatar="{ row }">
            <img
              :src="row.head_portrait"
              alt=""
              style="width: 50px; height: 50px"
            />
          </template>
          <template #handler="{ row }">
            <div>
              <el-button size="small" type="primary" @click="changeCoach(row)"
                >选择教练</el-button
              >
            </div>
          </template>
        </auto-table>
      </div>

      <div class="flex m-t20">
        <div class="m-r20 s-t">教练信息：</div>

        <div class="flex" v-if="coachInfo.id">
          <img
            :src="coachInfo.head_portrait"
            alt=""
            style="width: 50px; height: 50px"
          />
          <div class="m-l20">
            <div>
              {{ coachInfo.real_name }}
              <span> {{ coachInfo.sex == 1 ? "男" : "女" }}</span>
            </div>
            <div>教龄：{{ coachInfo.teaching_age }}年</div>
            <div>
              {{ coachInfo.teaching_categories }}
            </div>
          </div>
        </div>
        <div v-else>可搜索并选择教练</div>
      </div>
    </div>

    <div class="part">
      <div class="title">开通套餐</div>
      <div class="grid">
        <div
          class="prod"
          @click="current = i"
          v-for="(prod, i) in productList"
          :key="prod.id"
          :class="current === i ? 'ac-prod' : ''"
        >
          <div class="p-name">套餐： {{ prod.name }}</div>
          <div class="p-price">
            ¥{{ prod.discount_amount }}
            <span class="p-l" v-if="prod.amount > prod.discount_amount"
              >¥{{ prod.amount }}</span
            >
          </div>
          <div class="p-desc">
            {{ prod.introduce }}
          </div>
        </div>
      </div>
    </div>

<div>
     <div class="s-t">申请说明</div>
       <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="apply_remark"
          >
          </el-input>
</div>
    

    <div class="foot flex jc-b">
      <div></div>
      <el-button type="success" @click="submitApply">申请IP服务</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      DataList: [],
      Option: [
        { name: "头像", value: "avatar", type: "custom" },
        { name: "名称", value: "real_name" },
        { name: "城市", value: "city" },
        { name: "电话", value: "phone" },
        { name: "类目", value: "teaching_categories" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      keyWord: "",
      coachInfo: {},
      show: false,
      productList: [],
      current: 0,
      apply_remark:'',
       adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
    };
  },

  mounted() {
    this.getProductList();
  },

  methods: {
    submitApply() {
      if (!this.coachInfo.id) {
        this.$message("请选择教练");
        return;
      }
      if (!this.productList[this.current]) {
        this.$message("请选择服务套餐");
        return;
      }
      this.$confirm("确认提交教练IP服务开通申请？").then((res) => {
         let data = {
        coach_id: this.coachInfo.id,
        coach_name: this.coachInfo.real_name,
        remark: this.apply_remark,
        type: "coach_ip",
        submit_id: this.adminInfo.id,
        submit_name: this.adminInfo.name,
        amount:this.productList[this.current].discount_amount,
        product_id:this.productList[this.current].id
      };
      this.$post("/user/authApply/insert", data).then((res) => {
        this.$alert("申请提交成功，请等待运营人员审核通过。").then(()=>{
            this.$router.back()
        })
      });
      });
    },
    changeCoach(row) {
      this.coachInfo = row;
      this.show = false;
    },
    getProductList() {
      this.$axios({
        url: "/user/product/query",
        params: {
          status: 1,
          type: "coach_ip",
        },
      }).then((res) => {
        this.productList = res.data.data;
      });
    },
    //获取列表
    getcoachList(v) {
      let page = v || 1;
      if (!this.keyWord) {
        this.$message("请输入搜索数据");
        return;
      }
      const numberReg = /^\d/;
      this.show = true;
      let data = {
        status: 2,
        currentPage: page,
        pageSize: 10,
      };
      numberReg.test(this.keyWord)
        ? (data.phone = this.keyWord)
        : (data.real_name = this.keyWord);
      let url = "/user/coach/queryManagerListPage";

      this.$axios({
        url: url,
        params: data,
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.part {
  margin-bottom: 20px;
}
.title {
  font-size: 16px;
}
.s-t {
  font-size: 15px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}
.prod {
  border-radius: 10px;
  box-shadow: 1px 0px 20px rgba(196, 190, 171, 0.1);
  padding: 15px;
  border: 1px solid #eee;
  cursor: pointer;
  .p-name {
    font-size: 15px;
    font-weight: 600;
  }
  .p-price {
    margin-top: 8px;
    font-size: 18px;
    color: #f0ad4e;
  }
  .p-l {
    font-size: 14px;
    margin-left: 10px;
    text-decoration: line-through;
    color: rgb(97, 97, 97);
  }
  .p-desc {
    margin-top: 6px;
    font-size: 13px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-box-orient: vertical;
  }
}

.ac-prod {
  background-image: linear-gradient(60deg, #fff, #f1e4d9);
  border: 1px solid #f78d36;
}

.foot {
  position: fixed;
  bottom: 0;
  padding-right: 40px;
  padding-bottom: 30px;
}
</style>